<template>
  <div class="client-ambassador">
    <div class="ambassador-details">
      <div class="icon"><v-icon>mdi-headphones</v-icon></div>
      <div>
        <div class="nameA">{{ name }}</div>
        <div class="emailA">{{ mail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "mail"],
};
</script>

<style lang="scss" scoped>
.ambassador-details {
  display: flex;
//   align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d0d0d0;
  .icon {
    font-size: 1.25rem;
    color: #dd0e1a;
  }
  .nameA {
    font-size: 0.825rem;
    font-weight: 700;
  }
  .emailA {
    font-size: 0.825rem;
    font-weight: 400;
  }
}
</style>
