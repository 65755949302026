<template>
    <div class="shipping-container">
      <div class="shipping-card">
        <div class="title">Shipping Details</div>
        <div class="details-grid">
          <div class="detail-item">
            <v-icon class="icon">mdi-account</v-icon>
            <span class="label">Recipient:</span>
            <span class="value">{{ shippingData.name }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-map-marker</v-icon>
            <span class="label">Address:</span>
            <span class="value">{{ shippingData.address }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-city</v-icon>
            <span class="label">City:</span>
            <span class="value">{{ shippingData.city }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-email</v-icon>
            <span class="label">Postal Code:</span>
            <span class="value">{{ shippingData.postalCode }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-earth</v-icon>
            <span class="label">Country:</span>
            <span class="value">{{ shippingData.country }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-phone</v-icon>
            <span class="label">Phone:</span>
            <span class="value">{{ shippingData.phone }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      shippingData: {
        type: Object,
        required: true,
        default: () => ({
          name: "John Doe",
          address: "123 Main Street, Apt 4B",
          city: "New York",
          postalCode: "10001",
          country: "USA",
          phone: "+1 234 567 890",
        }),
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .shipping-container {
    display: flex;
  }
  .shipping-card {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .details-grid {
    display: grid;
    gap: 10px;
  }
  .detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 6px;
  }
  .icon {
    font-size: 1.25rem;
    color: #007bff;
  }
  .label {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .value {
    font-size: 0.875rem;
  }
  </style>
  