<template>
  <div>
    <div class="list-title">
      <v-icon class="icon">mdi-{{ icon }}</v-icon>
      <span class="list-name">{{ listName }}</span>
    </div>
    <div class="person-list py-1">
      <div v-for="person in list" :key="person.id" class="person-card">
        <CustomAvatar2
          class="ma-0 mb-2"
          :size="'medium'"
          :fName="person && person.first_name ? person.first_name : ''"
          :lName="person && person.last_name ? person.last_name : ''"
          :imgUrl="person && person.image_url ? person.image_url : null"
          :theme="person && person.theme ? person.theme : ''"
        />
        <div>
          <div class="nameA">{{ person.name }}</div>
          <div class="emailA">{{ person.email }}</div>
        </div>
        <div class="chipA">
          {{ person.code }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
export default {
  props: ["list", "listName", "icon"],
  components: { CustomAvatar2 },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  display: flex;
  padding: 0px .5rem;
  gap: 1rem;
  margin-bottom: 10px;
  .icon {
    font-size: 1.25rem;
    color: #dd0e1a;
  }
  .list-name {
    font-size: 1.25rem;
    font-weight: 700;
  }
}
.person-list {
  background: #fff;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  width: 100%;
  width: fit-content;
  height: 60vh;
  overflow: auto;

  .person-card {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #d9d9d9;
    justify-content: space-between;
    .nameA {
      font-size: 0.825rem;
      font-weight: 600;
    }
    .emailA {
      font-size: 0.75rem;
      font-weight: 400;
    }
    .chipA {
      padding: 2px 10px;
      background-color: #cdd6f8;
      font-size: 0.625rem;
      font-weight: 400;
      border-radius: 8px;
      height: fit-content;
    }
  }
  .person-card:nth-last-child(1) {
    border: none;
  }
}
</style>
