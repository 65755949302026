<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th class="noWrap">Company</th>
          <th class="noWrap">Division</th>
          <th class="noWrap">SBU</th>
          <th class="noWrap">Product Line</th>
          <th class="noWrap">Description</th>
          <th class="noWrap">Item Prefix</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in productData" :key="index">
          <td class="noWrap">{{ entry.company }}</td>
          <td class="noWrap">{{ entry.division }}</td>
          <td class="noWrap">{{ entry.sbu || "" }}</td>
          <td class="noWrap">{{ entry.product_line || "" }}</td>
          <td>{{ entry.description }}</td>
          <td class="noWrap">{{ entry.item_prefix || "" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productData: [
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CF",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CRB",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
        {
          company: "CF",
          division: "CBI",
          sbu: "CBI",
          product_line: "MM",
          item_prefix: "MM",
          description: "MEMBERS MARK",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;

    thead {
      background-color: #e3f2fd;
      border: 1px solid #cbf0fd;

      th {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 12px;
        text-align: left;
        color: #000;
      }
    }

    tbody {
      tr {
        font-size: 0.875rem;
        font-weight: 400;
        overflow: auto !important;
        transition: background-color 0.2s ease-in-out;

        &:nth-child(odd) {
          background-color: #ffffff;
        }

        td {
          padding: 10px;
          text-align: left;
          color: #333;
        }

        &:hover {
          background-color: #ebf1f3;
        }
      }
    }
  }
}

.noWrap {
  white-space: nowrap;
}
</style>
