<template>
    <v-card width="100%" style="overflow: auto;">
      <v-form  ref="form">
        <v-card-item>
          <v-card-title>
            <v-btn  elevation="0" @click="$emit('closeDialog')" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
          </v-card-title>
        </v-card-item>  
        <v-card-text class="pt-4">
        <PrimeDataTable :title="`Time Sheet Details${totalTime?`(${totalTime})`:''}`" :showFilter="false" :searchOption="true"  :selectAll="false"
            :showDownload="false" :reduceHeight="true" :allowTable="true" :showUpload="false" :showAdd="false" :columnData="columns" :data="data" />    
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import PrimeDataTable from '@/components/PrimeDataTable.vue';
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService';
export default {
    props: ['issueData'],
    components: {
        PrimeDataTable
    },
    data() {
        return {
            data: [],
            totalTime:null,
            columns:[
                        {
                    field: "resource_name",
                    header: "Resource Name",
                    style: { width: "100px", color: "" },
                    toolTip: "Code",
                    sortfield: true,
                    },
                    {
                    field: "entry_date",
                    header: "Entry_date",
                    type:"datetime",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                    },

                    {
                    field: "start_date",
                    header: "Start Date",
                    type:"datetime",
                    style: { width: "100px", color: "" },
                    toolTip: "RoleType",
                    sortfield: true,
                    },
                    {
                    field: "end_date",
                    header: "End Date",
                    type:"datetime",
                    style: { width: "100px", color: "" },
                    toolTip: "Modified",
                    sortfield: true,
                    },
                    {
                    field: "actual_hrs",
                    header: "Actual Time(Minutes)",
                    style: { width: "100px", color: "" },
                    toolTip: "Modified",
                    sortfield: true,
                    }
            ]
        }
    },
    watch: {

    },
    created() {
        console.log('created###',this.issueData);
        if(this.issueData && this.issueData.id){
            this.getTSData()
        }
    },
    methods: {
        async getTSData() { // TS -time sheest
            try {
                let res = await TimeSheetService.getIssueTimeLine({ issue_id: this.issueData.id });
                this.data = res && res.length > 0 ? res : [];
                this.getTotalTime()
            } catch (exception) {
                this.data = [];
                console.log(exception, 'error');
            }
        },
        getTotalTime(){
            this.totalTime = this.data.reduce((sum, item) => {
            return sum + item.actual_hrs
            }, 0).toFixed(2)
            
        }
    }
}
</script>
<style scoped></style>

