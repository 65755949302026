<template>
<v-card flat class="pa-3">
    <v-row class="pl-2">
      <v-col cols="2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Type of the Question [E]
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                class="dropdown"
                density="compact"
                :items="assessmentType" item-value="display_value" item-title="display_value"
                v-model="formData.display_value"
                placeholder="Type of the question"
                @update:model-value="changeFilter"
              />
      </v-col>
      <v-col cols="2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Level
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                class="dropdown"
                density="compact"
                :items="level" item-value="display_value" item-title="display_value"
                v-model="formData.level"
                placeholder="Select Level"
                @update:model-value="changeFilter"
              />
      </v-col>
      <v-col cols="2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Category
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                class="dropdown"
                density="compact"
                :items="assessmentCategory" item-value="id" item-title="name"
                v-model="formData.category_id"
                placeholder="Select ..."
                @update:model-value="changeFilter"
              />
      </v-col>
      
      <v-col cols="2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Department
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                class="dropdown"
                density="compact"
                :items="quetionDepartment" item-value="display_value" item-title="display_value"
                v-model="formData.question_department"
                placeholder="Select ..."
                @update:model-value="changeFilter"
              />
      </v-col>
      <v-col cols="2">
        <v-btn style="margin-top: 30px;" color="primary" @click="clearFilter" variant="outlined">Clear Filter</v-btn>
      </v-col>
    </v-row>
    <v-row style="padding-top:-29px!important;overflow: auto;">
      <PrimeDataTable
          :title="'Questionnaire'"
          :showFilter="true"
          :searchOption="true"
          :selectAll="true"
          :showDownload="false"
          :checkBoxOnRow="false"
          :showUpload="false"
          :showAdd="true"
          :columnData="columns"
          :data="QuestionnaireData"
          @addDiolog="addDiolog()"
          @rowAction="rowActions($event)"
          activity="questionnaire"
          class="responsive-table"
        />
    </v-row>
    

    <v-dialog v-model="dialog" width="1000px" persistent>
      <AddQuestionnaire
        @closeCard="closeCard"
        :id="questionnaireId"
        :viewType="viewType"
        @reload="reloaded"
      />
    </v-dialog>
    </v-card>
</template>

<script>
import AddQuestionnaire from "./addQuestionnaire.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService.js";
import commonService from "@/common.service";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import commonStoreData from "@/common.store-data";
export default {
  components: {
    AddQuestionnaire,
    PrimeDataTable,
    AutoComplete
  },
  data() {
    return {
      columns: [],
      date: new Date(),
      questionnaireId: null,
      dialog: false,
      viewType: false,
      QuestionnaireData: [],
      reload:false,
      assessmentType:[],
      assessmentCategory:[],
      quetionDepartment:[],
      level:[],
      formData:{}
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getQuestionnaireData();
    this.getEnumData()
    this.getAssesmentCategoryData()
  },
  methods: {
    async changeFilter(){
      console.log('datata ###',this.formData );
      this.QuestionnaireData = await commonService.addedFilter(this.formData,this.bkQuestionnaireData)
    },
    clearFilter(){
      this.formData = {}
      this.QuestionnaireData = JSON.parse(JSON.stringify([]))
    },
    async getEnumData(){
      this.assessmentType = await commonStoreData.getEnumData({domain:'ASSESSMENT_TYPE_OF_QUES',active:1},'SET_ASSESSMENT_TYPE_OF_QUES','getAssessmentType')
      this.level = await commonStoreData.getEnumData({domain:'ASSESSMENT',active:1},'SET_ASSESSMENT_LEVEL','getAssessmentLevel')
      this.quetionDepartment = await commonStoreData.getEnumData({domain:'QUESTION_DEPARTMENT',active:1},'SET_QUESTION_DEPARTMENT','getQuestionDepartment')
    },
    async getAssesmentCategoryData(){
      this.assessmentCategory = await commonStoreData.getAssessmentCategory({active:1})
    },
    closeCard(value) {
      this.dialog = value;
    },
    reloaded() {
        this.getQuestionnaireData();
    },
    loadColumns() {
      this.columns = [
        {
          field: "title2",
          header: "Question",
          style: { width: "100px", color: "" },
          toolTip: "Question",
          sortfield: true,
        },
        {
          field: "display_value",
          header: "Type Of The Question",
          style: { width: "100px", color: "" },
          toolTip: "Type Of The Question",
          sortfield: true,
        },
        {
          field: "name",
          header: "Assessment Category",
          style: { width: "100px", color: "" },
          toolTip: "Assessment Category",
          sortfield: true,
        },
        {
          field: "level",
          header: "Level",
          style: { width: "100px", color: "" },
          toolTip: "Level",
          sortfield: true,
        },
        {
          field: "question_department",
          header: "Department",
          style: { width: "100px", color: "" },
          toolTip: "Level",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [{ action: "view" }, { action: "edit" }],
        },
      ];
    },
    rowActions($event) {
      if ($event.type === "view" || $event.type === "edit") {
        this.dialog = true;
        this.questionnaireId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type === "select") {
        this.dialog = true;
        this.questionnaireId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getQuestionnaireData() {
      let $uesrDetail =  JSON.parse(localStorage.getItem('user_details'));
      $uesrDetail.department = typeof $uesrDetail.department == 'string' ? JSON.parse($uesrDetail.department) : $uesrDetail.department;
      let res = await QuestionnaireService.findList({department : $uesrDetail.department});
      // alert("Hi")
      // this.QuestionnaireData = res && res.length>0 ? res : [];
      this.bkQuestionnaireData = JSON.parse(JSON.stringify(res && res.length>0 ? res : []))
      console.log(this.QuestionnaireData,"hello user")
    },
    addDiolog() {
      this.dialog = true;
      this.questionnaireId = null;
      this.viewType = false;
    },
    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table-container {
    padding: 0 8px;
  }
}
</style>
