<template>
  
  <v-card 
    elevation="0"
    class="pa-4 pt-2"
    style="overflow: auto;height: 100%;margin-left: 10px"
  >

   <div class="cardTitle pt-0">
    Do you Know?<v-icon color="#eec137" class="pl-2 pb-2">mdi-lightbulb-on-outline</v-icon>
      </div>
    <v-row v-for="item in GetData" :key="item" class="pt-2">
      <v-col cols="1"
        ><Avatar
          icon="pi pi-flag"
          class=""
          style="background-color: #e7e7f5; color: #6366f1"
          shape="circle"
        ></Avatar
      ></v-col>
      <v-col cols="10" class="labeltext">{{ item.label }}</v-col>
    </v-row>
  </v-card>
</template>

<script>
import Avatar from "primevue/avatar";
import DashboardService from "@/api/service/Dashboard/DashboardService.js";
export default {
  props:["userId"],
  components: {
    Avatar,
  },
  data() {
    return {
      GetData: [],
    };
  },
  watch:{
    userId(){
      if(this.userId){
        this.getinsights(this.userId)
      }
    }
  },
  created() {
    this.getinsights(this.userId);
  },
  methods: {
    async getinsights(id) {
      let res = await DashboardService.insights({userId:id});
      console.log(this.res, "ress");
      this.GetData = res;
    },
  },
};
</script>

<style scoped>
.cardTitle {
  color: #050505f0;
  font-size: 18px;
  font-weight: 700;
}
.labeltext {
  font-size: 14px;
  font-weight: 500;
  padding-top: 18px;
}
</style>