<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          <b> Edit Details</b>
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row class="px-2 py-0 my-0" no-gutters>
          <v-col cols="12" class="py-0" v-if="accountshow && notFoundworkspace">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >WorkSpace
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="workspaceData"
              :placeholder="'Workspace'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.work_space_id"
              :rules="workspaceRule"
              @update:modelValue="getProjectAndAccountData"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="accountshow && notFoundworkspace">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Project
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="projectListData"
              :placeholder="'Project'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.project_id"
              :rules="projectRule"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="accountshow && notFoundworkspace">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Account
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="accountListData"
              :placeholder="'Account'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.account_id"
              :rules="accountRule"
            />
          </v-col>

          <v-col cols="6" class="pr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Due On</v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Due on"
              type="datetime-local"
              showtime
              hoursFormat="24"
              v-model="formData.end_date"
              :disabled="true"
              :min="getCurrentDateTime()"
            />
          </v-col>

          <v-col cols="6" v-if="showNextAction" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Assignee
            </v-label>
            <AutoComplete
              :items="users"
              :placeholder="'Assignee'"
              item-title="username"
              :item-value="'id'"
              v-model="formData.next_action"
            />
          </v-col>

          <v-col cols="6" v-if="this.skillId" class="pr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Category
            </v-label>
            <AutoComplete
              :items="categoryData"
              :placeholder="'Category'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.category_id"
              @update:modelValue="selectCategory()"
            />
          </v-col>

          <v-col cols="6" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sub Category
            </v-label>
            <AutoComplete
              :items="subCategoryData"
              :placeholder="'Sub Category'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.sub_category_id"
            />
          </v-col>

          <v-col cols="6" class="pr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sub Task-type
            </v-label>
            <AutoComplete
              :items="taskData"
              :placeholder="'Sub task-type'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.task_id"
            />
          </v-col>

          <v-col cols="6" v-if="this.quantityID" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Quantity
            </v-label>
            <TextFieldComponent
              density="compact"
              @keydown="floatValue($event)"
              placeholder="quantity"
              v-model="formData.quantity"
            />
          </v-col>

          <v-col cols="6" v-if="this.skillId" class="pr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Skill
            </v-label>
            <AutoComplete
              :items="skills"
              :placeholder="'Skill'"
              :item-value="'id'"
              :item-title="'name'"
              :rules="projectData && projectData.skill ? skillRule : ''"
              :disabled="showReadOnly"
              v-model="formData.skill_id"
            />
          </v-col>

          <v-col cols="6" class="pl-2" v-if="showEstimate">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Estimates
            </v-label>
            <TextFieldComponent
              density="compact"
              @keydown="floatValue($event)"
              placeholder="estimate"
              v-model="formData.estimate"
            />
          </v-col>

          <v-col cols="6" class="pr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Stage
            </v-label>

            <AutoComplete
              :items="stages"
              item-value="code"
              item-title="name"
              density="compact"
              placeholder="Select Stage"
              v-model="formData.stage"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form></v-card
  >
</template>

<script>
import moment from "moment";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import IssueService from "@/api/service/Issue/IssueService";
// import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";
import UserService from "@/api/service/UserService";
import StageService from "@/api/service/Stage/StageService";
import RiskService from "@/api/service/Risk/RiskService";
import ProjectService from "@/api/service/Project/ProjectService";
import commonService from "@/common.service";
import TaskService from "@/api/service/Task/TaskService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
//import AccountService from "@/api/service/Account/AccountService";
import RoleService from "@/api/service/Role/RoleService";

export default {
  props: ["id","showNextAction"],
  components: {
    AutoComplete,
    TextFieldComponent,
    // EnumAutoComplete
  },
  data() {
    return {
      dialog: false,
      workspaceData:[],
      projectListData:[],
      showEstimate:false,
      notFoundworkspace:true,
      users: [],
      stages: [],
      quantityID: "",
      accountshow: false,
      accountListData: [],
      skillId: "",
      projectData: {},
      accountRule: [(value) => !!value || "Account is Required"],
      workspaceRule:[(value) => !!value || "Workspace is Required"],
      projectRule:[(value) => !!value || "Project is Required"],
      workSpaceId: null,
      projectId: null,
      skills: [],
      taskData: [],
      categoryData: [],
      subCategoryData: [],
      userDetails:null,
      formData: {
        end_date: null,
        next_action: null,
        submitted_by: null,
        skill_id: null,
        estimate: null,
        stage: null,
        work_space_id: null,
        project_id:null,
        account_id:null
      },
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
  },
  async created() {
    if (this.id) {
      this.getById(this.id);
    }
    this.getWorkspace();
    let userDetails = localStorage.getItem("user_details");
    userDetails = userDetails ? JSON.parse(userDetails) : {}; 
    this.userDetails = userDetails
    this.accountshow =
      userDetails &&
      userDetails.roles &&
      userDetails.roles.role_type &&
      userDetails.roles.role_type < 7
        ? true
        : false;
    let wsDetails = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"));
    console.log(wsDetails,"workspace"); 
    this.workSpaceId =
      wsDetails && wsDetails.workspace && wsDetails.workspace.id
        ? wsDetails.workspace.id
        : null;
    this.projectId =
      wsDetails && wsDetails.project && wsDetails.project.id
        ? wsDetails.project.id
        : null;
    if (wsDetails && wsDetails.project && wsDetails.project.id) {
      try {
        let $projects = await ProjectService.findAll({ id: wsDetails.project.id });
        this.skillId = $projects[0].skill;
        this.quantityID = $projects[0].quantity;
        this.getCategoryData(wsDetails.project.id);
        this.getSubCategoryData(this.formData.category_id);
      } catch (e) {
        this.projectData = {};
      }
    }
   
    this.getSkills();
    this.getuser();
    this.getStage();
    this.getTaskData();
    this.getEstimateAccess()
 
  },
  methods: {
    moment,
    async getEstimateAccess(){
      let res = await RoleService.getAccessForRule({access_role:'TL',condition:'above',user_role:this.userDetails.roles.code})
      if(res && res == true){
        this.showEstimate = true
        return 
      }
      let res1 = await RoleService.getAccessForRule({access_role:'CA',condition:'above',user_role:this.userDetails.roles.code})
      if(res1 && res1 == true){
        this.showEstimate = true
        return 
      }
      this.showEstimate = false
    },
    async getWorkspace() {
        let res = await WorkSpaceService.getWorkSpaceByUser({ active: 1 });
        this.workspaceData = res.map((workspace) => ({
        ...workspace,
        project_id: JSON.parse(workspace.project_id ||[])
      }));
      const selectedWorkspace = this.workspaceData.find(
        (workspace) => workspace.id === this.formData.work_space_id
      );
      this.notFoundworkspace= selectedWorkspace ? true:false;
       this.projectListData = await ProjectService.findAll({ id:selectedWorkspace.project_id });
      this.getAccounts(this.formData.work_space_id);
    },

    async getProjectAndAccountData(selectedWorkspaceId) {
        const selectedWorkspace = this.workspaceData.find(
        (workspace) => workspace.id === selectedWorkspaceId
      );
        this.projectListData = await ProjectService.findAll({ id: selectedWorkspace.project_id });
        this.getAccounts(selectedWorkspaceId);
        this.formData.account_id=null;
        this.formData.project_id=null;
    },
    close() {
      this.$emit("closeDialog");
    },
    getAccounts(id) {
      let obj = {
        workSpaceId: id 
      };
      WorkSpaceService.getAccounts(obj)
        .then((res) => {
          if (res && res.accounts && res.accounts.length > 0) {
            this.accountListData = res.accounts;
          }else{
            this.accountListData=[];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    async getById(id) {
      let res = await IssueService.findOne(id);
      this.formData = res[0];
      this.formData.id = res[0].id;
      this.formData.end_date = res[0].end_date
        ? moment(res[0].end_date).format("YYYY-MM-DD HH:mm:ss")
        : moment().format("YYYY-MM-DD HH:mm:ss");
      this.getSubCategoryData(this.formData.category_id);
    },
    changeStage($event) {
      this.formData.stage = $event.code;
    },

    floatValue(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    async getCategoryData(projectId) {
      try {
        // let $projects = await ProjectService.findAll({ id: projectId });
        // this.selectedProject = $projects && $projects.length > 0 ? $projects[0] : {};
        // let $categoryId = $projects[0].category_id;
        let res = await CategoryService.categoryById({project_id:projectId});
        // let res = await CategoryService.findAll();
        this.categoryData =
          res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      } catch (e) {
        console.log(e);
      }
    },

    selectCategory() {
      this.getSubCategoryData();
      this.formData.sub_category_id = null;
    },

    async getSubCategoryData() {
      let res = await SubCategoryService.getAll({
        category_id: this.formData.category_id,
      });
      this.subCategoryData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    async getTaskData() {
      let res = await TaskService.findAll({ task_type: "production" });
      this.taskData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    async getuser() {
      try {
        let wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"));
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await UserService.getNextAction({
          work_space_id: workSpaceId,
          account_id: wsDefaults.account.id,
          project_id: wsDefaults.project.id,
        });
        this.users = res.length ? res : [];
      } catch (e) {
        this.users = [];
      }
    },
    async getStage() {
      let res = await StageService.findAll();
      this.stages = res;
    },

    async getSkills() {
      let res = await RiskService.findAll();
      this.skills = res;
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        // if (this.formData && this.formData.end_date) {
        this.formData.end_date = commonService.changeTheDateToUtc(
          this.formData.end_date,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        // }
        await IssueService.update(this.formData);
        this.$store.dispatch("setSnackbar", { text: "Details Updated Successfully" });
        this.dialog = false;
        this.close();
        this.$emit("reload");
        this.formData = {};
      }
    },
  },
};
</script>

<style scoped>
</style>
