<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-card-item>
      <v-card-title
      class="py-0"
        >
       Edit Time review
        <v-btn
          @click="close()"
          elevation="0"
          density="comfortable"
          icon="mdi-close"
          class="x-closeIcon"
        ></v-btn>
      </v-card-title>
    </v-card-item>

    <v-form @submit.prevent="save()" ref="form" v-model="value">
      <v-card-text>
        <v-row no-gutters class="p-2">
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Account</v-label
            >
            <AutoComplete
              :items="accountsData"
              item-value="id"
              item-title="name"
              density="compact"
              placeholder="Account"
              v-model="formData.account_id"
              @update:modelValue="accountChanged"
            />
          </v-col>

          <v-col cols="4" class="px-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Project
            </v-label>
            <AutoComplete
              :items="projectData"
              item-value="id"
              item-title="name"
              density="compact"
              placeholder="Projects"
              v-model="formData.project_id"
              @update:modelValue="projectChanged"
            />
          </v-col>

          <v-col cols="4" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sub Category
            </v-label>
            <AutoComplete
              :items="subCategoryData"
              item-value="id"
              item-title="name"
              density="compact"
              placeholder="Sub Category"
              v-model="formData.sub_category_id"
              @update:modelValue="subCategoryChanged"
            />
          </v-col>

          <v-col cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Actual Hours
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Actual Hours"
              :maxlength="4"
              v-model="formData.actual_hrs"
              @keydown="prevent1($event)"
            />
          </v-col>

          <v-col cols="6" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Paid Hours
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Paid Hours"
              :maxlength="4"
              v-model="formData.billed_hrs"
              @keydown="prevent1($event)"
            />
          </v-col>
          <v-col cols="12" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Description"
              v-model="formData.description"
            ></TextFieldComponent>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import AccountService from "@/api/service/Account/AccountService.js";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import commonService from "@/common.service";
import ProjectService from "@/api/service/Project/ProjectService";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
export default {
  props: ["id", "data"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      projectData: [],
      accountsData: [],
      subCategoryData: [],
    //   subCategoryData: [],
      formData: {
        account_id: null,
        project_id: null,
        sub_category_id: null,
        actual_hrs: null,
        billed_hrs: null,
        description: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  async created() {
    this.getProjectData()
    this.getSubCategoryData()
    this.getAccountData()
    if (this.id) {
      await this.getById(this.id);
    }
  },

  methods: {
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      console.log(id ,'iduhhgj')
      let timeData = await TimeSheetService.findAll({id:id});
      this.formData = timeData[0];
      console.log(timeData ,'anyThing')
    },

    async getSubCategoryData() {
      let res = await SubCategoryService.findAll();
      this.subCategoryData = res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    async getProjectData() {
      try {
        let res = await ProjectService.findAll();
        this.projectData =
          res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      } catch (e) {
        this.projectData = [];
        console.log(e);
      }
    },
    async getAccountData() {
      try {
        let res = await AccountService.findAll();
        this.accountsData =
          res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      } catch (e) {
        this.accountsData = [];
        console.log(e);
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("error")
      } else {
        if (this.formData.id) {
          await TimeSheetService.update(this.formData)
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
<style>
.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}
</style>
