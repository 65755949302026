<template>
  <div class="client-details">
    <HeaderComponent />
    <div class="tabs">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
        @click="activeTab = index"
      >
        {{ tab.name }}
      </button>
    </div>
    <div class="content">
      <component :is="tabs[activeTab].component" />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./HeaderComponent.vue";
import WarehouseDetails from "./WarehouseDetails.vue";
import PointOfContact from "./PointOfContanct/PointOfContact.vue";
import ProductDetails from "./ProductDetails/ProductDetails.vue";       
import ShippingDetails from './ShippingDetails.vue';
import CustomerDetails from './CustomerDetails.vue'
import ExternalParties from './ExternalParties.vue'

export default {
  components: {
    HeaderComponent,
    PointOfContact,
    WarehouseDetails,
    ProductDetails,
    ShippingDetails,
    CustomerDetails,
    ExternalParties
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        { name: "Point of Contact", component: "PointOfContact" },
        { name: "External Parties", component: "ExternalParties" },
        { name: "Product Details", component: "ProductDetails" },
        { name: "Customer Details", component: "CustomerDetails" },
        { name: "Shipping Details", component: "ShippingDetails" },
        { name: "Warehouse", component: "WarehouseDetails" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.client-details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  button {
    border: none;
    cursor: pointer;
    transition: 0.1s;
    font-size: 0.875rem;

    &.active {
      font-weight: 700;
      border-radius: 8px;
      padding: 5px 10px;
      background: #dd0e1a;
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
}

.content {
  width: 100%;
}
</style>
