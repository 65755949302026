<template>
  <div class="description mb-2"><div>{{description}}</div></div>
</template>

<script>
export default {
    props:['description']

}
</script>

<style lang="scss" scoped>
.description{
    background: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    div{
        width: 86%;
        font-size:0.875rem;
        font-weight: 400;
    }
}

</style>