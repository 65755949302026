<template>
  <div class="timesheet-profile">
    <CustomAvatar2
      class="ma-0 mb-2"
      :size="'medium'"
      :fName="
        userDetails && userDetails.first_name ? userDetails.first_name : ''
      "
      :lName="userDetails && userDetails.last_name ? userDetails.last_name : ''"
      :imgUrl="
        userDetails && userDetails.image_url ? userDetails.image_url : null
      "
      :theme="userDetails && userDetails.theme ? userDetails.theme : ''"
    >
    </CustomAvatar2>
    <div class="user-info">
      <div class="user-name">
        {{ userDetails && userDetails.name ? userDetails.name : "" }}
      </div>
      <div class="user-details">
        <span>
          {{
            userDetails && userDetails.roles && userDetails.roles.name
              ? `${userDetails.roles.name} `
              : ""
          }}</span
        >
        <span v-if="userDetails && userDetails.date_of_join && userDetails.roles && userDetails.roles.name">|</span>
        <span>
          {{
            userDetails.date_of_join
              ? ` ${moment(userDetails.date_of_join).fromNow()}`
              : ""
          }}</span
        >
        <span v-if="userDetails && userDetails.date_of_join ">|</span>
        <span>{{
          userDetails.date_of_join
            ? `${moment(userDetails.date_of_join).format("YYYY-MM-DD")}`
            : ""
        }}</span>
      </div>
      <v-icon
        id="nextaction-menu-activators"
        style="cursor: pointer"
        color="#636363"
        size="14px"
        class="editIcon"
        >mdi mdi-pencil</v-icon
      >
      <NextAction
        activator="#nextaction-menu-activators"
        :options="users"
        @menuSelected="userSelected"
        :showAvatar="true"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NextAction from "@/modules/IssueV2/NextAction.vue";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import UserService from "@/api/service/UserService";
export default {
  props: ["userDetails"],
  components: {
    CustomAvatar2,
    NextAction
  },
  data() {
    return {
      users:[]
    };
  },
  created() {
    this.getUserData()
  },
  methods: {
    moment,
    async getUserData() {
      let res = await UserService.getReporterUserData();
      this.users = res ? res : [];
      this.users.unshift( this.userDetails);
    },
    userSelected($item) {
      console.log($item,'userSelected')
      this.$emit("userChanged", $item);
    },
  },
};
</script>

<style scoped>
.timesheet-profile {
  position: relative;
  display: flex;
  padding: 0rem 1rem;
  line-height: 2;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  background-color: #ebf1f3;
  border: 1px solid #d2d2d2;
}
.user-name {
  font-size: 1rem;
  font-weight: 500;
}
.user-details {
  font-size: 0.75rem;
  font-weight: 400;
}
.editIcon{
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border-radius: 50%;
  top: 5px;
  right: 5px;
}
</style>
