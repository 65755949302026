import ApiService from '../../service/apiServiceMaster.js';
import TimesheetApiService from '../../service/apiServiceTimesheets.js';
const querystring = require('querystring')
var TimeSheetService = {
    
    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "timesheets"
            $options.method = "get"
            if ($data) {
                $options.url = "timesheets?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getIssueTimeLine: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getIssueTimeLine"
            $options.method = "get"
            if ($data) {
                $options.url = "getIssueTimeLine?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getTimeSheetUserData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTimeSheetUserData"
            $options.method = "get"
            if ($data) {
                $options.url = "getTimeSheetUserData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "timesheets/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "timesheets/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "timesheets" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "timesheets";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    approveTimeSheet: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "approveTimeSheet";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    setApprove: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "setApprove";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    updateMeetingRemarks: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = `updateMeetingRemarks`
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    updateWTData: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = `updateWTData`
            $options.method = "post"
            console.log($options)
            TimesheetApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    processTime2: function($data,$type,$start) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = `processTime?type=${$type}&start=${$start}`
            $options.method = "post"
            console.log($options)
            TimesheetApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    createMeeting: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "createMeeting";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
                
        });
    },
    
    getAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "timeSheetList"
            $options.method = "get"

            if ($data) {
                $options.url = "timeSheetList?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getAllApproveData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAllApproveData"
            $options.method = "get"

            if ($data) {
                $options.url = "getAllApproveData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getTimesheetId: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTimeSheetById"
            $options.method = "get"

            if ($data) {
                $options.url = "getTimeSheetById?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getTimeSheetByUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTimeSheetByUser"
            $options.method = "get"

            if ($data) {
                $options.url = "getTimeSheetByUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getDashboardData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "dashboardSummary"
            $options.method = "get"

            if ($data) {
                $options.url = "dashboardSummary?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getDashboardPersonal:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "dashboardPersonal"
            $options.method = "get"

            if ($data) {
                $options.url = "dashboardPersonal?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getTimeForIssue:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTimeForIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "getTimeForIssue?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getErrorTrends:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getErrorTrends"
            $options.method = "get"

            if ($data) {
                $options.url = "getErrorTrends?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getGroupByData:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getGroupByData"
            $options.method = "get"

            if ($data) {
                $options.url = "getGroupByData?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                console.log(response,'alertt888')
                resolve(response.data.data)

            }).catch(error => {
                reject(error);
            })
        });
    },
    getGroupByLead:function($data) {
        
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getGroupByLead"
            $options.method = "get"

            if ($data) {
                $options.url = "getGroupByLead?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                console.log(response,'alertt888')
                resolve(response.data.data)

            }).catch(error => {
                reject(error);
            })
        });
    },
    getReviewTimeSheet:function($data) {
        
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getReviewTimeSheet"
            $options.method = "get"

            if ($data) {
                $options.url = "getReviewTimeSheet?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                console.log(response,'alertt888')
                resolve(response.data.data)

            }).catch(error => {
                reject(error);
            })
        });
    },
    getTSData:function($data) {
        
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTSData"
            $options.method = "get"

            if ($data) {
                $options.url = "getTSData?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                console.log(response,'alertt888')
                resolve(response.data.data)

            }).catch(error => {
                reject(error);
            })
        });
    },


};
export default TimeSheetService;