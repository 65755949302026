const apiConfig = {
        "gatWayUrl": "https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
        // "baseUrl":" https://synergy.axodesk.com:8081",
        //"baseUrl":"https://synergy-uat.axodesk.com:8081",
        //"baseUrl":"https://synergy-api.coreforce.com:8081",
        "baseUrl":"https://synergy-api.coreforce.com:8081",            
"baseUrlDocument":"https://synergy-api.coreforce.com:8083",
/*"baseUrlMaster":"https://service1.axodesk.com:8082",       
"baseUrlReport": "https://service1.axodesk.com:8083",      
"baseUrlMasterProject":"https://service1.axodesk.com:8084",   
"baseUrlOthers": "https://service1.axodesk.com:8085",      
"baseUrlIssue":"https://service2.axodesk.com:8082",
"baseUrlMasterWs":"https://service2.axodesk.com:8083",   
"baseUrlBoard":"https://service3.axodesk.com:8082",       
"baseUrlIdentity": "https://service4.axodesk.com:8083",   
"baseUrlSecurity": "https://service4.axodesk.com:8084",
"baseUrlTimesheet": "https://synergy-timesheet.axodesk.com:8098",    
*/
"baseUrlMaster":"https://synergyserver1.coreforce.com:8082",
"baseUrlReport": "https://synergyserver1.coreforce.com:8083",
"baseUrlMasterProject":"https://synergyserver1.coreforce.com:8084",
"baseUrlOthers": "https://synergyserver1.coreforce.com:8085",
"baseUrlIssue":"https://synergyserver2.coreforce.com:8082",
"baseUrlMasterWs":"https://synergyserver2.coreforce.com:8083",
"baseUrlBoard":"https://synergyserver3.coreforce.com:8082",
"baseUrlIdentity": "https://synergyserver4.coreforce.com:8083",
"baseUrlSecurity": "https://synergyserver4.coreforce.com:8084",
"baseUrlCache": "https://synergy-cache-api.axodesk.com:8096",
"baseUrlAssesments":"https://synergyserver3.coreforce.com:8083",
"baseUrlTimesheet": "https://synergy-timesheet.axodesk.com:8082",


	"appName": "Identti Front End",
        "apiKey": "apiKey",
        "secrect": "secrect",
        "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281"
}
export default apiConfig;
