<template>
    <div>
      <div class="list-title">
        <v-icon class="icon">mdi-warehouse</v-icon>
        <span class="list-name">Warehouse</span>
      </div>
  
      <div class="warehouse-grid">
        <div
          class="wrapper-list p-2 mb-4"
          v-for="(wareHouse, index) in warehouseData"
          :key="index"
        >
          <div class="warehouse-header">
            <span class="list-name">{{ wareHouse.details.name }}</span>
            <span class="opening-time">{{ wareHouse.details.openingTime }}</span>
          </div>
  
          <div class="warehouse-address my-4">
            {{ wareHouse.details.address }}
          </div>
  
          <div class="person-list">
            <div v-for="(person, i) in wareHouse.data" :key="i" class="person-card">
              <CustomAvatar2
                class="ma-0 mb-2"
                :size="'medium'"
                :fName="person?.first_name || ''"
                :lName="person?.last_name || ''"
                :imgUrl="person?.image_url || null"
                :theme="person?.theme || ''"
              />
              <div class="person-info">
                <div class="nameA">{{ person.name }}</div>
                <div class="emailA">
                  <span>{{ person.email }} </span>
                  <v-icon class="icon">mdi-content-copy</v-icon>
                </div>
                <div class="phoneA">
                  <span>{{ person.phone }} </span>
                  <v-icon class="icon">mdi-content-copy</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
export default {
  props: [],
  components: { CustomAvatar2 },
  data() {
    return {
      warehouseData: [
        {
          details: {
            name: "MMT",
            openingTime: "09:30 AM",
            address:
              "5226 South 31st Place, Phoenix, AZ 85040 Phone: 800-849-5844,Fax: 1-800-525-7959",
          },
          data: [
            {
              code: "ABR",
              name: "Abraham D Cohen",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Aaron F Harari",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Baruk",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Jack Cohen",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Joe Chera",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Steven Cohen",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "ABR",
              name: "Kenneth Tawil",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
          ],
        },
        {
          details: {
            name: "FLS",
            openingTime: "09:30 AM",
            address:
              "5226 South 31st Place, Phoenix, AZ 85040 Phone: 800-849-5844,Fax: 1-800-525-7959",
          },
          data: [
            {
              code: "ABR",
              name: "Tyra",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "Bage",
              name: "Aaron F Harari",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
          ],
        },
        {
          details: {
            name: "FLS",
            openingTime: "09:30 AM",
            address:
              "5226 South 31st Place, Phoenix, AZ 85040 Phone: 800-849-5844,Fax: 1-800-525-7959",
          },
          data: [
            {
              code: "ABR",
              name: "Tyra",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
            {
              code: "Bage",
              name: "Aaron F Harari",
              email: "abraham@creativebrandsllc.com",
              phone: "+1 203  123-4567",
            },
          ],
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.list-title {
  display: flex;
  padding: 0px 0.5rem;
  gap: 1rem;
  margin-bottom: 10px;
  align-items: center;

  .icon {
    font-size: 1.25rem;
    color: #dd0e1a;
  }
}

.list-name {
  font-size: 1.25rem;
  font-weight: 700;
}

.opening-time {
  font-size: 1.125rem;
  font-weight: 700;
}

.warehouse-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.wrapper-list {
  background: #e4f0f3;
  border-radius: 8px;
  border: 1px solid #10caf8;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 380px; 
  min-width: 280px;
}

.warehouse-header {
  display: flex;
  justify-content: space-between;
}

.warehouse-address {
  max-width: 70%;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
}

.person-list {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 50vh;
  overflow: auto;
}

.person-card {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
}

.person-info {
  width: 100%;
}

.nameA {
  font-size: 0.825rem;
  font-weight: 600;
}

.phoneA,
.emailA {
  display: flex;
  justify-content: space-between;
  font-size: 0.825rem;
  font-weight: 400;

  .icon {
    font-size: 0.825rem;
    color: #dd0e1a;
  }
}

.person-card:last-child {
  border-bottom: none;
}

@media (max-width: 600px) {
  .warehouse-grid {
    grid-template-columns: 1fr;
  }

  .wrapper-list {
    width: 100%;
  }
}
</style>
