<template>
  <div class="input-wrapper">
    <!-- {{ created }} -->
    <div class="timesheet-profile">
      <div class="inputwrapper">
        <v-label class="pb-1">Vacation</v-label>
        <TextFieldComponent
          density="compact"
          placeholder="Vacation"
          v-model="formData.vacation"
          @input="validateInput('vacation')"
          :showReadOnly="isFieldDisabled('vacation')"
        ></TextFieldComponent>
      </div>

      <div class="inputwrapper">
        <v-label class="pb-1">PTO</v-label>
        <TextFieldComponent
          density="compact"
          placeholder="PTO"
          v-model="formData.pto"
          @input="validateInput('pto')"
          :showReadOnly="isFieldDisabled('pto')"
        ></TextFieldComponent>
      </div>

      <div class="inputwrapper">
        <v-label class="pb-1">LOP</v-label>
        <TextFieldComponent
          density="compact"
          placeholder="LOP"
          v-model="formData.lop"
          @input="validateInput('lop')"
          :showReadOnly="isFieldDisabled('lop')"
        ></TextFieldComponent>
      </div>
    </div>

    <div style="display: flex; align-items: center">
      <button class="save" @click="saveData">SUBMIT</button>
    </div>
  </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import TaskService from '@/api/service/Task/TaskService';

export default {
  props: ["data","date"],
  components: {
    TextFieldComponent,
  },
  data() {
    return {

      formData: {
        vacation: "",
        pto: "",
        lop: "",
      },
      created: new Date(this.date).toISOString()
    };
  },
  watch: {
    date() {
      if (this.date) {
       this.created =new Date(this.date).toISOString()
      }
    }
  },
  computed: {
    activeField() {
      return Object.keys(this.formData).find((key) => this.formData[key]);
    },
  },
  methods: {
    isFieldDisabled(field) {
      return this.activeField && this.activeField !== field;
    },
    validateInput(field) {
      this.formData[field] = this.formData[field]
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    },
    async saveData() {
  if (!this.formData.vacation && !this.formData.pto && !this.formData.lop) {
    this.$store.dispatch("setSnackbar", {
      text: "Please enter at least one value",
      color: "error",
    });
    return;
  }

  const getTaskId = async (code) => {
    const task = await TaskService.findAll({ code });
    return task?.[0]?.id || null;
  };

  let data = {};
  if (this.formData.vacation) {
    data = {
      task_id: await getTaskId("VAC"),
      actual_hrs: 480,
      billed_hrs: 480,
      created:this.created
    };
  } else if (this.formData.lop) {
    data = {
      task_id: await getTaskId("LOP"),
      actual_hrs: this.formData.lop,
      billed_hrs: -this.formData.lop,
      created:this.created
    };
  } else {
    data = {
      task_id: await getTaskId("PTA"),
      actual_hrs: this.formData.pto,
      billed_hrs: 0,
      created:this.created
    };
  }

  console.log(data ,'datatat')

  await TimeSheetService.create(data);
  this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });

  this.formData = { vacation: "", pto: "", lop: "" };
  this.$emit("reload", true);
}

  },
};
</script>

<style scoped>
.input-wrapper {
  width: 60%;
  display: flex;
  gap: 1rem;
}

.timesheet-profile {
  display: flex;
  padding: 1rem 1rem;
  width: 100%;
  gap: 1rem;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  background-color: #ebf1f3;
}
.inputwrapper {
  width: 30%;
}
.save {
  background-color: #1258ab;
  border: 1px solid #d2d2d2;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
  height: fit-content;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}
>>> .v-input__details {
  display: contents;
}
</style>
