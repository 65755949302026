<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form  ref="form">
      <v-card-item>
        <v-card-title>          
          Please Confirm
          <v-btn
            @click="closeDialog"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
            style="font-size: 20px;"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col style="padding: 30px 0px 30px 0px">
            Reference# <span class="ref-num">{{ issueData && issueData.reference_num ? `[${issueData.reference_num}]`:''}}</span> task required QA, still do you need to close the Task
          </v-col> 
        </v-row>
      </v-card-text>

      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn class="mr-2 cancel"  @click="closeDialog">No</v-btn>
          <v-btn class="save" :loader="loader" @click="save">Yes</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import IssueService from '@/api/service/Issue/IssueService';
import ForumService from '@/api/service/Forum/ForumService';
export default {
  props: ['data','issueData'],
  data(){
    return{
      loader:false
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    async save(){			
        let userData = localStorage.getItem('user_details')
        userData = userData ? JSON.parse(userData) : {}
        this.loader = true
        let $issueStatus = this.$store.getters.getIssueStatus;				
        await IssueService.update(this.data);
        if($issueStatus && $issueStatus ==1){
          this.$store.commit("SET_ISSUE_STATUS",2);
        }
        let data = {
          key_type: "issue",
          key_id: this.data.id,
          description: `QA required Task, without QA [${userData && userData.username ? userData.username : ''}] has changed the task ${this.issueData && this.issueData.stage ? this.issueData.stage:''} to Closed`,
        };
        await ForumService.create(data);
        this.closeDialog();
        this.$emit("saveDialog");
    }
  },
};
</script>
<style scoped>
.ref-num{
  color: red;
  font-weight: bold;
}
</style>
