
const logicCondition = {
    remarksRequired: ['TR',
        'CA',
        'ME',
        'DSR',
        'ASS',
        'MPW',
        'RES',
        'STS',
        'MNG'],
    bypassStage:[
        'open',
        'hold',
        'assigned',
        'receivedreply'
    ],
    commonAccountCode:'CFA'
}
export default logicCondition;