<template>
  <div class="header">
    <div class="company-info">
      <div class="clientName pb-2">Creative Brands LLC</div>
      <div class="clientAddress">5226 South 31st Place, Phoenix, AZ 85040</div>
      <div class="clientAddress">Phone: 800-849-5844, Fax: 1-800-525-7959</div>
    </div>
    <div class="ambassador-wrapper">
      <div v-for="(item, i) in ambassadorData" :key="i">
        <AmbassadorCard :name="item.name" :mail="item.email" />
      </div>

      <div>
        <v-img
          style="margin: 0 auto"
          src="./clientLogo.svg"
          height="40px"
          width="auto"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import AmbassadorCard from "@/components/AmbassadorCard.vue";
export default {
  components: { AmbassadorCard },
  data() {
    return {
      ambassadorData: [
        { name: "Radica Hajaree", email: "radica@coreforce.com" },
        { name: "Client Ambassador", email: "radica@coreforce.com" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }

  .company-info {
    text-wrap: nowrap;
    text-align: left;
    margin-bottom: 10px;
    .clientName {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .clientAddress {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .ambassador-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  .ambassador-wrapper > div:nth-last-child(1) {
    grid-column: 4;

    @media (max-width: 768px) {
      grid-column: 1;
    }

    @media (max-width: 480px) {
      grid-column: 1;
    }
  }
}
</style>
