<template>
    <div class="owner-details">
      <div v-for="(item, i) in ownerData" :key="i">
        <Owner :name="item.name" :mail="item.email" :title="item.title"/>
      </div>
    </div>
  </template>
  
  <script>
import Owner from '@/components/Owner.vue';
  export default {
    components:{Owner},
    data(){
      return{
        ownerData: [
        { name: "Fred Harari", email: "Fred@creativebrandsllc.com",title:'Owner' },
        { name: "Kenneth Tawil", email: "ktawil@creativebrandsllc.com",title:'Co-owner'},
        { name: "Jack Cohen", email: "jack@creativebrandsllc.com",title:'Co-owner'},
      ],
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .owner-details {
    display: flex;
    gap: 15px;
    width: 100%;

    @media (max-width: 768px) { 
    flex-wrap: wrap;
  }

  @media (max-width: 480px) { 
    flex-wrap: wrap;
  }
  }
  </style>