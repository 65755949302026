<template>
  <div>
    <OwnerDetails />
  </div>
  <div class="bottom-section">
    <PersonList :listName="'Salesperson'" :icon="'account-arrow-right-outline'" :list="salespersons"/>
    <PersonList :listName="'Production Owners'" :icon="'factory'" :list="salespersons"/>
  </div>
</template>

<script>
import OwnerDetails from "./OwnerDetails.vue";
// import ProductionOwners from "./ProductionOwners.vue";
import PersonList from "./PersonList.vue";
export default {
  components: {
    OwnerDetails,
    PersonList,
    // ProductionOwners,
  },
  data() {
    return {
      salespersons: [
        {
          code: "ABR",
          name: "Abraham D Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Aaron F Harari",
          email: "abraham@creativebrandsllc.com",
        },
        { code: "ABR", name: "Baruk", email: "abraham@creativebrandsllc.com" },
        {
          code: "ABR",
          name: "Jack Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Joe Chera",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Steven Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Kenneth Tawil",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Abraham D Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Aaron F Harari",
          email: "abraham@creativebrandsllc.com",
        },
        { code: "ABR", name: "Baruk", email: "abraham@creativebrandsllc.com" },
        {
          code: "ABR",
          name: "Jack Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Joe Chera",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Steven Cohen",
          email: "abraham@creativebrandsllc.com",
        },
        {
          code: "ABR",
          name: "Kenneth Tawil",
          email: "abraham@creativebrandsllc.com",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bottom-section {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: start;

  @media (max-width: 768px) { 
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) { 
    grid-template-columns: 1fr;
  }
}


</style>
