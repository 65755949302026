<template>
    <div class="header-container">
            <TimesheetUserDetails :userDetails="userDetails" @userChanged="userChanged"/>
            <TimesheetInputDetails v-if="showEdit" :date="date" :timesheetData="timesheetData" @reload="$emit('reload')" />
            </div>
  </template>
  
  <script>
  import TimesheetInputDetails from "./TimesheetInputDetails.vue";
  import TimesheetUserDetails from "./TimesheetUserDetails.vue";
  
  export default {
    components: {
      TimesheetUserDetails,
      TimesheetInputDetails,
    },
    props:['userDetails','showEdit','date'],
    data() {
      return {
      };
    },
    methods: {
      saveData(data) {
        this.$emit('saveData',data)
      },
      userChanged(data){
      this.$emit("userChanged", data);}
    }
  };
  </script>
  

  <style scoped>
  .header-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}</style>