<template>
  <div class="px-5 pt-6 pb-4">
    <div class="pb-2">
      <span class="timesheet-title">Time sheet Review</span>
    </div>
    <v-row class="timesheet-container">
      <v-col cols="12" md="10">
        <TimesheetHeader
          :userDetails="userDetails"
          :showEdit="showEdit"
          :date="selectedDate"
          @reload="reload"
          @userChanged="userChanged"
        />
        <TimesheetTable
          :timesheetData="timesheetData"
          :showEdit="showEdit"
          @editEntry="editEntry"
        />
      </v-col>
      <v-col cols="12" md="2" class="side-panel">
        <TimesheetSidePanel
          :date="selectedDate"
          :data="overViewData"
          @changeDate="changeDate"
        />
      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialog" width="600px">
      <EditTime @closeDialog="dialog = false" @reload="reload" :id="editId" />
    </v-dialog>
  </div>
</template>

<script>
import EditTime from "./EditTime.vue";
import TimesheetHeader from "./TimesheetHeader.vue";
import TimesheetTable from "./TimesheetTable.vue";
import TimesheetSidePanel from "./TimesheetSidePanel.vue";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService.js";
import moment from "moment";

export default {
  components: {
    TimesheetHeader,
    TimesheetTable,
    TimesheetSidePanel,
    EditTime,
  },
  data() {
    return {
      dialog: false,
      showEdit: true,
      editId: null,
      selectedDate: new Date(),
      userId: "",
      loggedUser: "",
      userDetails: {},
      overViewData: {},
      timesheetData: [
      ],
    };
  },

  created() {
    let data = localStorage.getItem("user_details");
    if (data) {
      this.userDetails = JSON.parse(data);
      this.loggedUser = this.userDetails.id;
      this.userId = this.loggedUser;
    }
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.getTimeReviewData(this.userId, this.selectedDate);
    this.getTSData(this.userId, this.selectedDate);
  },

  methods: {
    moment,
    reload() {
      this.getTimeReviewData(this.userId, this.selectedDate);
      this.getTSData(this.userId, this.selectedDate);
    },
    userChanged(data) {
      this.userDetails = data;
      this.userId = this.userDetails.id;
      this.getTimeReviewData(this.userId, this.selectedDate);
      this.getTSData(this.userId, this.selectedDate);
      this.showEdit = this.loggedUser === this.userId ? true : false;
    },
    changeDate(newDate) {
      this.selectedDate = moment(newDate).format("YYYY-MM-DD");
      this.getTimeReviewData(this.userId, this.selectedDate);
      this.getTSData(this.userId, this.selectedDate);
    },
    editEntry(index) {
      this.editId = index;
      this.dialog = true;
    },
    async getTimeReviewData(userId, date) {
      try {
        let res = await TimeSheetService.getReviewTimeSheet({
          user_id: userId,
          date: date,
        });
        if (res) {
          this.overViewData = res[0];
          console.log(this.overViewData, "overViewData");
        }
      } catch (e) {
        console.log("Error:", e);
      }
    },
    async getTSData(userId, date) {
      try {
        let res = await TimeSheetService.getTSData({
          user_id: userId,
          date: date,
        });

        if (res) {
          this.timesheetData = res.filter(
            (e) =>
              e.description !== "login" &&
              e.description !== "Auto Logout" &&
              e.description !== "logout"
          );
        }
      } catch (e) {
        console.log("Error:", e);
      }
    },
  },
};
</script>

<style scoped>
.timesheet-review {
  display: flex;
}
.timesheet-title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
