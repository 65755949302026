<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th class="noWrap">Account</th>
          <th class="noWrap">Project</th>
          <th class="noWrap">Sub Category/Task</th>
          <th class="noWrap">Actual Hours</th>
          <th class="noWrap">Paid Hours</th>
          <th class="noWrap">Description</th>
          <th v-if="showEdit"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in timesheetData" :key="index">
          <td class="noWrap">{{ entry.account_name }}</td>
          <td class="noWrap">{{ entry.project_name }}</td>
          <td class="noWrap">{{ entry.issue_id ? entry.sc_name : entry.task_name}}</td>
          <td class="noWrap">{{ entry && entry.actual_hrs ?( entry.actual_hrs  / 60 ).toFixed(2): ''}}</td>
          <td class="noWrap">{{ entry && entry.billed_hrs ?( entry.billed_hrs  / 60 ).toFixed(2): ''}}</td>
          <td>{{ entry.description }}</td>
          <td v-if="showEdit">
            <v-btn flat  icon="mdi-pencil" size="x-small"  class="edit-btn" @click="$emit('editEntry', entry.id)">
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["timesheetData",'showEdit'],
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  background: #ebf1f35a;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      border: 1px solid #cbf0fd;
      background-color: #e3f2fd;

      th {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 12px;
        text-align: left;
        color: #000;
      }
    }

    tbody {
      tr {
        font-size: 0.875rem;
        font-weight: 400;
        position: relative;
        overflow: hidden;
        border: 1px solid #cbf0fd;
        border-radius: 8px;

        &:nth-child(odd) {
          background-color: #ebf1f3;
        }

        &:nth-child(even) {
          background-color: #fff;
        }

        td {
          padding: 4px;
          text-align: left;
          color: #333;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.edit-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.noWrap{
  text-wrap:nowrap;
}
</style>
