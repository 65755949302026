<template>
    <div class="customer-container">
      <div class="customer-card">
        <div class="title">Customer Details</div>
        <div class="details-grid">
          <div class="detail-item">
            <v-icon class="icon">mdi-account</v-icon>
            <span class="label">Full Name:</span>
            <span class="value">{{ customerData.fullName }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-email</v-icon>
            <span class="label">Email:</span>
            <span class="value">{{ customerData.email }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-phone</v-icon>
            <span class="label">Phone:</span>
            <span class="value">{{ customerData.phone }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-map-marker</v-icon>
            <span class="label">Address:</span>
            <span class="value">{{ customerData.address }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-city</v-icon>
            <span class="label">City:</span>
            <span class="value">{{ customerData.city }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-earth</v-icon>
            <span class="label">Country:</span>
            <span class="value">{{ customerData.country }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="icon">mdi-card-account-details</v-icon>
            <span class="label">Customer ID:</span>
            <span class="value">{{ customerData.customerId }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      customerData: {
        type: Object,
        required: true,
        default: () => ({
          fullName: "Jane Doe",
          email: "jane.doe@example.com",
          phone: "+1 987 654 3210",
          address: "456 Elm Street, Apt 7C",
          city: "Los Angeles",
          country: "USA",
          customerId: "CUST-12345",
        }),
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .customer-container {
    display: flex;
  }
  .customer-card {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .details-grid {
    display: grid;
    gap: 10px;
  }
  .detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 6px;
  }
  .icon {
    font-size: 1.25rem;
    color: #007bff;
  }
  .label {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .value {
    font-size: 0.875rem;
  }
  </style>
  