import EnumService from './api/service/Enum/EnumService.js';
import AssessmentCategoryService from './api/service/AssessmentCategory/AssessmentCategoryService.js';
import store from './store/index.js'


class commonStoreDataService {
    constructor() {

    }
    async getEnumData($filter,$storeName,storeFun){
        try {
            let storeData = store.getters[storeFun];
            if (storeData) return storeData;
        
            let result = (await EnumService.findAllDomain($filter)) || [];
            store.commit($storeName, result.length ? result : []);        
            return result;
        } catch (exception) {
            console.log(exception, 'error');
            store.commit($storeName, []);
            return null;
        }      

    }
    async getAssessmentCategory($filter,$storeName,storeFun){
        try {
            let storeData = store.getters[storeFun||'getAssessmentCategory'];
            if (storeData) return storeData;
        
            let result = (await AssessmentCategoryService.findAll($filter)) || [];
            store.commit($storeName||'SET_ASSESSMENT_TYPE_OF_QUES', result.length ? result : []);        
            return result;
        } catch (exception) {
            console.log(exception, 'error');
            store.commit($storeName||'SET_ASSESSMENT_TYPE_OF_QUES', []);
            return null;
        }      

    }
    
}
export default new commonStoreDataService();