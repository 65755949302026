<template>
	<v-layout>
		<v-navigation-drawer id="issue-view" v-model="drawer" class="issue-view-container" style="width: 950px"
			location="right" fixed>
			<v-container fluid class="pa-0 ma-0" @dragleave="toggleGridLines" @dragover.prevent @dragenter="toggleGridLines"
				@drop="handleDrop">
				<v-card flat dense v-if="issueData && issueData.id" class="pt-0 px-3 pb-3">
					<Header :exceptStage="exceptStage" :showNextAction="showNextAction" :showEdit="showEdit" :issueData="issueData"
						@closedrawer="closeDrawer" :totalViews="totalViews" :totalFollowers="totalFollowers"
						:totalShares="totalShares" @updateData="updateData" @shared="issueShared"
						@reload-issue-data="getIssue()"
						@show-error="showError"
						@openClarification="openClarification('view')" @togglefollowing="togglefollowing"
						@edit="openDialog('editDetails')" @extended-menu-selected="extendedMenuSelected" />
					<v-card-text dense style="margin-top: 190px">
						<v-row dense class="pt-0">
							<v-col cols="8" class="pt-0">
								<v-row dense>
									<v-col cols="12" class="mt-1">
										<span style="font-size: 16px; font-weight: 600; color: #272727">One Line Comment</span>
										<v-textarea variant="outlined" class="mt-1 text-Field" rows="1" v-model="issueData.one_line_comment"
											@change="saveOneLineComment"></v-textarea>
										<div v-if="mandatory" class="errorMsg">Please provide a valid reason for canceling this task.</div>
										<div v-if="mandatory1" class="errorMsg">{{ messageOfError }}</div>
									</v-col>
									<v-col cols="12" class="mt-1">
										<stageDataView v-if="issueData.stage == 'clarification' ||
                      issueData.stage == 'itsupportrequired' ||
                      issueData.stage == 'hold' ||
                      issueData.stage == 'followup'
                      " @reload="getIssue()" :reload="stageDataReload" :issueData="issueData" />
									</v-col>
									<v-col cols="12">
										<Description :issueData="issueData" :showEdit="showEdit" :exceptStage="exceptStage"
											@descriptionChanged="descriptionChanged" />
									</v-col>
									<v-col cols="12">
										<div accept="null" :style="options.showDragContainer
                      ? 'height:100px;border:2px dashed #adadad'
                      : ''
                      ">
											<Attachments :fileForUpload="fileForUpload" @removeDoc="removeDoc" :exceptStage="exceptStage"
												:type="type" :issueId="issueData.id" :showEdit="showEdit"
												:noDisplay="options.showDragContainer" />
										</div>
									</v-col>
									<v-col cols="12">
										<Forum :issueId="issueData && issueData.id ? issueData.id : null" :newComment="newComment"
											@viewsUpdated="updateViews" />
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="4">
								<v-row dense class="mt-7">
									<v-col cols="12">
										<SubTasks :exceptStage="exceptStage" :showEdit="showEdit" :parentId="issueData.id"
											:subTaskDefaults="getSubTaskDefaults()"
											:parentSubject="issueData && issueData.name ? issueData.name : null" />
									</v-col>
									<v-col cols="12">
										<QaTasks :exceptStage="exceptStage" :showEdit="showEdit" :halfScreen="halfScreen" type="review"
											:allow="getAllow" @qaClicked="qaDialogue()" @setRefreshFalse="updateQA = false"
											:issueId="issueData && issueData.id" :issueData="issueData" @updateData="updateData"  :refresh="updateQA" />

										<QaTasks :exceptStage="exceptStage" :showEdit="showEdit" @updateData="updateData" :issueId="issueData.id" class="mt-3"
											:issueData="issueData" />
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-container>
			<v-dialog v-model="dialogOpen" :width="800" persistent>
				<EditDetails @closeDialog="closeDialog()" v-if="options.editDetails" ref="EditDetails"
					:showNextAction="showNextAction" :id="issueData.id" @reload="
            closeDialog();
          getIssue();
          " />
			</v-dialog>
			<v-dialog v-model="clfnDialog" persistent :width="600">
				<CreateClarification @closeDialog="closeDialog()" @saveDialog="getIssue()" ref="ShowMailDetails"
					:issueId="issueData.id" :showDetails="showDetails" :followUpNo="issueData.no_of_followups" />
			</v-dialog>
			<v-dialog v-model="holdDialog" persistent :width="600">
				<CreateHold @closeDialog="closeDialog()" @saveDialog="getIssue()" ref="ShowMailDetails" :issueId="issueData.id" />
			</v-dialog>
			<v-dialog v-model="flpDialog" persistent :width="600">
				<CreateFollowUp @closeDialog="closeDialog()" @saveDialog="getIssue()" ref="ShowMailDetails"
					:issueId="issueData.id" :no_of_followups="issueData.no_of_followups"
					@followup-updated="issueData.no_of_followups = issueData.no_of_followups + 1" />
			</v-dialog>
			<v-dialog v-model="itFlpDialog" persistent :width="600">
				<CreateItFollowup @closeDialog="closeDialog()" @saveDialog="getIssue()" ref="ShowMailDetails"
					:issueId="issueData.id" :no_of_followups="issueData.no_of_followups" />
			</v-dialog>
			
			<v-dialog v-model="errorlogQa" persistent :width="1000">				
				<ErrorLogQa ref="QaTask" @closeDialog="errorlogQa = false" @add-qa-task="errorlogQa = true"  @updateData="updateData"  :qaFail="true" :issueData="issueData" :timeSheetDefaults="{issueId:issueData.id,pass:'0'}" />
			</v-dialog>
			<v-dialog v-model="confirmationDialog"  persistent :width="600">
				<Confirmation :issueData="issueData" v-on:close="confirmationDialog = false" @saveDialog="getIssue()" :data="issueUpdateData" />
			</v-dialog>
		</v-navigation-drawer>
	</v-layout>
</template>
<script>
//import moment from 'moment-timezone'
import CreateClarification from "./CreateClarification.vue";
import Header from "./Header.vue";
import Description from "./Description.vue";
import Attachments from "./Attachments.vue";
import Forum from "./Forum.vue";
import SubTasks from "./SubTasks.vue";
import QaTasks from "./QaTasks.vue";
import EditDetails from "./EditDetails";
import IssueService from "@/api/service/Issue/IssueService";
// import documentService from "@/api/service/Document/DocumentService"
import ForumService from "@/api/service/Forum/ForumService";
import CreateFollowUp from "./CreateFollowUp.vue";
import CreateHold from "./CreateHold.vue";
import CreateItFollowup from "./CreateItFollowup.vue";
import stageDataView from "./stageDataView.vue";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import ErrorLogQa from '../issues/views/QaTask.vue'
// import ShowMailDetails from './ShowMailDetails.vue'
import Confirmation from './Confirmation.vue'

export default {
	props: ["issueId"],
	components: {
		Header,
		Confirmation,
		Attachments,
		Description,
		Forum,
		SubTasks,
		QaTasks,
		EditDetails,
		CreateItFollowup,
		CreateClarification,
		CreateHold,
		CreateFollowUp,
		stageDataView,
		ErrorLogQa,
		// ShowMailDetails
	},
	data() {
		return {
			userDetails:null,
			mandatory: false,
			mandatory1: false,
			messageOfError:'',   
			bacupIssueData:null,
			stageDataReload: false,
			allIssuesClosed: false,
			showNextAction: false,
			showDetails: "",
			showEdit: true,
			confirmationDialog:false,
			exceptStage: true,
			newComment: {},
			issueUpdateData:null,
			totalViews: [],
			totalFollowers: [],
			totalShares: [],
			issueData: {},
			getAllow: false,
			drawer: false,
			dialogOpen: false,
			tempData: null,
			clfnDialog: false,
			holdDialog: false,
			itFlpDialog: false,
			flpDialog: false,
			fileForUpload: null,
			type: null,
			errorlogQa:false,
			options: {
				editDetails: false,
				showDragContainer: false,
			},
		};
	},
	async created() {
		this.drawer = true;
		this.getIssue();
		// await this.ifChildClosed()
	},
	watch: {
		refreshData() {
			// alert('hi')
		},
		isDrawerOpen() {
			this.drawer = this.$store.getters.getIssueViewDrawer;
		},
		issueId() {
			this.drawer = true;
			this.getIssue();
			// alert('chagned')
		},
		reloadTheData() {
			this.getIssue();
		},
		getQaAddStatus(){
			this.errorlogQa = true
		}
	},
	computed: {
		isDrawerOpen() {
			return this.$store.getters.getIssueViewDrawer;
		},
		reloadTheData() {
			return this.$store.getters.getReloadTheIssue;
		},		      
        getQaAddStatus() {
            return this.$store.getters.getQaAdd;
        },
	},
	methods: {
		getAccessNextAction(workSpaceId) {
			let userData = localStorage.getItem('user_details')
			userData = userData ? JSON.parse(userData) : {}
			this.userData = userData
			WorkSpaceService.getAccessNextAction({
				workSpaceId: workSpaceId,
				roleId: userData && userData.roles && userData.roles.id ? userData.roles.id : null
			}).then(res => {
				this.showNextAction = res && res.length > 0 ? true : false
			}).catch(e => {
				this.showNextAction = false
				console.log(e)
			})

		},
		async extendedMenuSelected($selected) {
			if ($selected.code == "followup") {
				this.flpDialog = true;
				let $data = {
					id: this.issueData.id,
					stage: "followup",
				};
				await IssueService.update($data);
				this.issueData.stage = "followup";
			} else if ($selected.code == "itSupportfollowup") {
				this.itFlpDialog = true;
				let $data = {
					id: this.issueData.id,
					stage: "itsupportrequired",
				};
				await IssueService.update($data);
				this.issueData.stage = "itsupportrequired";
			} else if ($selected.code == "qa") {
				let $data = {
					id: this.issueData.id,
					qa_required: this.issueData.qa_required == 1 ? 0 : 1,
				};
				await IssueService.update($data);
				this.issueData.qa_required = this.issueData.qa_required == 1 ? 0 : 1;
			}
		},
		async saveOneLineComment() {
			let data = {
				id: this.issueData.id,
				one_line_comment: this.issueData.one_line_comment
					? this.issueData.one_line_comment
					: null,
			};
			await IssueService.update(data);
		},
		closeDrawer() {
			this.drawer = false;
			this.$store.commit("SET_ISSUE_VIEW_DRAWER", this.drawer);
			this.issueData = null;
		},
		async getForumData() { },

		// async ifChildClosed() {
		// let res = await IssueService.findAll()
		// let allIssues = res.filter((elm) => {
		// if (this.issueData && this.issueData.id) {
		// return elm.parent_issue_id === this.issueData.id
		// }
		// });

		// // this.allIssuesClosed = allIssues.every((issue) => {
		// // return issue.stage == 'closed'
		// // });

		// console.log("closedHai", this.allIssuesClosed, allIssues);

		// },
		async issueShared($item) {
			let data = {
				key_type: "issue",
				key_id: this.issueId,
				action: "share",
				description: `${$item.shared_by.name} shared this issue with ${$item.shared_with.name} `,
			};
			let $response = await ForumService.create(data);

			this.newComment = $response.data.data[0];
		},
		async togglefollowing($userId, $status, $username) {
			let data = {
				key_type: "issue",
				key_id: this.issueId,
				action: $status ? "follow" : "unfollow",
				description: `${$username} ${$status ? "started following" : " unfollowed"
					} the issue `,
			};
			let $response = await ForumService.create(data);

			this.newComment = $response.data.data[0];
		},
		updateViews($totalViews, $totalFollowers, $totalShares) {
			this.totalViews = $totalViews;
			this.totalFollowers = $totalFollowers;
			this.totalShares = $totalShares;
		},
		toggleGridLines() {
			this.options.showDragContainer = !this.options.showDragContainer;
		},
		async handleDrop($event) {
			$event.preventDefault();
			this.options.showDragContainer = false;
			this.fileForUpload = $event.dataTransfer.files;
			this.type = "drop";
			console.log(this.fileForUpload, this.type, "started");
		},
		resetOptions() {
			this.options.editDetails = false;
		},
		openDialog($type) {
			this.resetOptions();
			this.dialogOpen = true;
			this.options[$type] = true;
		},

		openClarification(type) {
			if (type == "view") {
				this.showDetails = "view";
			} else {
				this.showDetails = "add";
			}
			this.clfnDialog = true;
		},
		openHold() {
			this.holdDialog = true;
		},
		oprnFollowUp() {
			this.flpDialog = true;
		},
		closeDialog() {
			this.resetOptions();
			this.dialogOpen = false;
			this.clfnDialog = false;
			this.holdDialog = false;
			this.flpDialog = false;
			this.itFlpDialog = false;
			this.showDetails = "";
			this.stageDataReload = !this.stageDataReload;
		},
		descriptionChanged($description) {
			this.issueData.description = $description;
		},

		async getIssue() {
			// let status = this.$store.getters.getReloadTheIssue;
			let $issueData = await IssueService.getIssueById({
				issue_id: this.issueId,
			});
			this.issueData = $issueData;
			this.bacupIssueData = JSON.parse(JSON.stringify($issueData));
			if (this.issueData) {
				this.getAccessNextAction(this.issueData.work_space_id)
			}

			console.log(this.issueData, "kkkkkkkkkkkkkkkkkkkkkkkkk");

			// console.log(this.issueData , 'newTypeIssue')
			this.$store.dispatch("issueViewed", this.issueId);
			if (this.issueData.stage == "inprogress") {
				this.showEdit = false;
			} else {
				this.showEdit = true;
			}
			if (this.issueData.stage == "closed" || this.issueData.stage == "completed") {
				this.exceptStage = false;
			} else {
				this.exceptStage = true;
			}
			this.getAllow = await this.getAllowing(this.issueData.stage);
			// this.$store.commit("RELOAD_THE_ISSUE", !status);
		},
		showError($msg){
			this.messageOfError = $msg
			this.mandatory1 = true
				setTimeout(() => {
					this.mandatory1 = false
				}, 3000);
		},
		async updateData($data, $type, $value2) {
			// console.log($value2,$data,$type,"2222222222bala");
			if($type == 'stage' && this.issueData.stage == 'errorlog' && $data.stage != 'errorlog' && this.userData && this.userData.roles && this.userData.roles.role_type && this.userData.roles.role_type>5){
				this.showError('Please reach concern Team leads to change the Error log correction.')
				return true
			}
			// let status = this.$store.getters.getReloadTheIssue;
			if (
				(await this.getAllowing($data.stage)) &&
				$data.stage !== "clarification" &&
				$data.stage !== "hold" &&
				$data.stage !== "followup" &&
				$data.stage !== "itsupportrequired"
			) {

				if ($type == "stage" && $data && $data.stage == "cancel") {
					if (this.issueData.one_line_comment != null) {
						let $issueStatus = this.$store.getters.getIssueStatus;				
						await IssueService.update($data);
						if($issueStatus && $issueStatus ==1){
							this.$store.commit("SET_ISSUE_STATUS",2);
						}
						// await IssueService.update($data);
					} else {
						this.mandatory = true
						setTimeout(() => {
							this.mandatory = false
						}, 3000);
					}
				} 
				else if($type == 'stage' && $data && $data.stage =='errorlog' && !$data.final){
					this.errorlogQa = true
				}
				else {
					if($data.final){
						this.errorlogQa = false	
						delete $data.final
					}
					console.log('#### stage',this.bacupIssueData,$data)
					if(this.issueData.qa_required == 1 && $type =='stage' && $data.stage == 'closed' && !this.issueData.parent_issue_id){
						this.issueUpdateData = $data
						this.confirmationDialog = true
						return true
					}
					else{
						let $issueStatus = this.$store.getters.getIssueStatus;				
						await IssueService.update($data);
						if($issueStatus && $issueStatus ==1){
							this.$store.commit("SET_ISSUE_STATUS",2);
						}
					}
					
				}
				// alert('Updating data...')
			}
			let $keys = Object.keys($data);
			for (let $key of $keys) {
				if ($key != "id") {
					this.issueData[$key] = $data[$key];
				}
			}
			if ($value2) {
				let $keys2 = Object.keys($value2);
				for (let $key of $keys2) {
					if ($key != "id") {
						this.issueData[$key] = $value2[$key];
					}
				}
			}

			if (this.tempData && this.tempData.length == 0) {
				console.log(this.tempData, "temp");
				if ($type == "stage" && $data && $data.stage == "clarification") {
					this.clfnDialog = true;
				} else if ($type == "stage" && $data && $data.stage == "hold") {
					this.holdDialog = true;
				} else if ($type == "stage" && $data && $data.stage == "followup") {
					this.flpDialog = true;
				} else if ($type == "stage" && $data && $data.stage == "itsupportrequired") {
					this.itFlpDialog = true;
				}
			}
			await this.getIssue();
			let status2 = this.$store.getters.getReloadSubTask;
			this.$store.dispatch("issueUpdated", this.issueData.id);
			this.$store.commit("RELOAD_THE_SUB_TASK", !status2);
		},

		async getAllowing(stage) {
			if (
				(stage == "completed") &&
				!this.issueData.parent_issue_id
			) {
				let res = await IssueService.getIssueClosed({ issueId: this.issueData.id });
				//alert('Here in the allowing #1')
				return res && res.length > 0 ? false : true;
			}
			if(stage =='closed'){
				let res = await IssueService.getIssueClosed2({ issueId: this.issueData.id });
				// alert('Here in the allowing #1')
				// console.log(res,"0000000000222")
				if(res && res.message && res.message =='Sub task not completed'){
          this.messageOfError = res.message
					this.mandatory1 = true
						setTimeout(() => {
							this.mandatory1 = false
						}, 3000);

						return false
				}
				else if(res && res.message && res.message =='Please put qa task pass or fail'){
          this.messageOfError = res.message
					this.mandatory1 = true
						setTimeout(() => {
							this.mandatory1 = false
						}, 3000);
						return false
				}
				else{
					return true
				}

				// return res && res.length > 0 ? false : true;
			// return false
			}
			/*if((stage!='completed' || stage!='closed' ) || this.issueData.parent_issue_id){
				alert('Here in the allowing #2 ')
				return true
			}*/
			if (this.issueData && this.issueData.id) {
				let res = await IssueService.getAllowOfQa({ issueId: this.issueData.id });
				//alert('Here in the allowing #3')
				this.tempData = res;
				return res && res.length > 0 ? false : true;
			} else {
				return false;
			}
		},
		async removeDoc(data) {
			console.log(data, "newDocs");
			await IssueService.update({
				id: this.issueData.id,
				attachment: JSON.stringify(data),
			});
		},
		getSubTaskDefaults() {
			if (this.issueData.id) {
				return {
					issue_parent_id: this.issueData.id,
					name: this.issueData.name,
					startDate: new Date(),
					account_id: this.issueData.account_id,
					project_id: this.issueData.project_id,
					task_id: this.issueData.task_id,
					priority: this.issueData.priority,
					category_id: this.issueData.category_id,
					sub_category_id: this.issueData.sub_category_id,
					issue_type_id: this.issueData.issue_type_id,
					stage: this.issueData.stage,
					end_date: this.issueData.end_date,
					skill_id: this.issueData.skill_id,
					mail_box: this.issueData.mail_box,
					mail_box_id: this.issueData.mail_box_id,
					mail_date: this.issueData.mail_date,
					mail_details: this.issueData.mail_details,
					mail_subject: this.issueData.mail_subject,
				};
			}
		},
	},
};
</script>
<style scoped>
.issue-view-container {
	box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
}

.text-caption2 {
	font-weight: 400;
	font-size: 12px;
}

.subtitle {
	font-size: 12px;
}

.toggle-button {
	display: flex;
	justify-content: space-between;
	justify-content: space-between;
	cursor: pointer;
}

.reportedBy {
	display: flex;
}

.toggle-button span {
	margin-right: 8px;
}

.toggle-button v-icon {
	transition: transform 0.3s;
}

.toggle-button:hover v-icon {
	transform: rotate(180deg);
}

.hoverEdit {
	display: flex;
	font-size: 14px;
}

.cancel {
	/* margin-top: 10px; */
	background: #3c5aaa;
	color: white;
}

.descBtn {
	display: flex;
	justify-content: end;
}

.editBtn {
	margin-right: 55px;
	margin-top: 10px;
}

.subtitle {
	font-size: 12px;
}

.tags {
	font-size: 12px;
	font-weight: 400;
	color: #272727;
}

>>>.v-chip--variant-tonal .v-chip__underlay {
	background-color: inherit;
	color: #272727 !important;
}

>>>.v-chip__content {
	color: #272727 !important;
}

.pink-bg {
	background-color: #f8d6e6 !important;
	opacity: 1 !important;
}

.purple-bg {
	background-color: #e2d6f8 !important;
}

.gold-bg {
	background-color: #f8f2d6;
}

.teal-bg {
	background-color: #d6f8f6;
}

.grey-bg {
	background-color: #f4f4f423;
}

.outlined {
	border: 1px solid #dfdfdf;
}

.bold-medium {
	font-weight: 600;
}

.description {
	font-size: 16px;
	font-weight: 400;
	color: #272727;
}

>>>p {
	font-size: 16px !important;
	font-weight: 400 !important;
	color: #272727 !important;
}

.btn-color {
	color: #333333;
	font-size: 10px;
	width: fit-content;
}

>>>.v-btn--size-default {
	--v-btn-size: 0;
	--v-btn-height: 32px;
	font-size: 0.875rem;
	min-width: 20px;
	padding: 0 4px;
}

.chevron {
	position: relative;
	bottom: 2px;
}

.errorMsg {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffe7e6;
	border: solid #ff5757;
	border-width: 0 0 0 6px;
	color: #ff5757;
	width: 575px;
	border-radius: 3px;
	height: 30px;
	margin-top: 3px;
}

>>>.v-navigation-drawer__content {
	flex: 0 1 auto;
	height: 99vh;
	overflow-y: auto;
}
</style>
