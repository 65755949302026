<template>
    <div class="product-section">
        <div>
        <ProductDescription :description="description" />
        <ProductTable />
    </div>
    <ProductGallary :imageData="imageData" />
  </div>
</template>

<script>
import ProductDescription from "./ProductDescription.vue";
import ProductTable from "./ProductTable.vue";
import ProductGallary from "./ProductGallary.vue";
export default {
  components: { ProductDescription, ProductGallary, ProductTable },
  data(){
    return{
        description:'CREATIVE BRANDS LLC., deals majorly with Car Accessories and Home products like tools, baskets',
        imageData: [
        "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0",
        "https://images.unsplash.com/photo-1521747116042-5a810fda9664",
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
        "https://images.unsplash.com/photo-1554151228-14d9def656e4",
        "https://images.unsplash.com/photo-1573497019239-6b1acb260507",
        "https://images.unsplash.com/photo-1590086782792-42dd303163b3",
        "https://images.unsplash.com/photo-1595433562696-13f252e2b7c6",
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde",
        "https://images.unsplash.com/photo-1511367461989-f85a21fda167",
        "https://images.unsplash.com/photo-1517423440428-a5a00ad493e8"
      ]
    }
  }
};
</script>
<style lang="scss" scoped>
.product-section {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: start;

  & > :nth-child(1) {
    grid-column: span 2;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    & > :nth-child(1) {
      grid-column: span 2;
    }
    & > :nth-child(2) {
      grid-column: span 2;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    & > * {
      grid-column: span 1;
    }
  }
}

</style>
