<template>
  <div class="side-panel">
    <div class="date-navigation">
      <button @click="changeDate(-1)">◀</button>
      <span>{{ date }}</span>
      <button @click="changeDate(1)">▶</button>
    </div>

    <div class="info-card login">
      <div class="icon-wrapper">
        <v-icon> mdi-arrow-right </v-icon>
      </div>
      <div>
        <div class="label">Login Time</div>
        <div class="value">{{data && data.login_date ? moment(data.login_date).format("h:mm A") :'' }}</div>
      </div>
    </div>
    <div class="info-card ts-logout">
      <div class="icon-wrapper">
        <v-icon> mdi-arrow-left </v-icon>
      </div>
      <div>
        <div class="label">Logout Time</div>
        <div class="value">{{data && data.logout_date ? moment(data.logout_date).format("h:mm A"):'' }}</div>
      </div>
    </div>
    <div class="info-card break">
      <div class="icon-wrapper">
        <v-icon> mdi-cupcake </v-icon>
      </div>
      <div>
        <div class="label">Break</div>
        <div class="value">
          {{
            data && data.break_time
              ? `${Math.floor(data.break_time / 60)} hours ${Math.round(
                  data.break_time % 60
                )} mins`
              : ""
          }}
        </div>
      </div>
    </div>
    <div class="info-card idle">
      <div class="icon-wrapper">
        <v-icon> mdi-tag-multiple </v-icon>
      </div>
      <div>
        <div class="label">Idle Time</div>
        <div class="value">
          {{
            data && data.inbetweentime
              ? `${Math.floor(data.inbetweentime / 60)} hours ${Math.round(
                  data.inbetweentime % 60
                )} mins`
              : ""
          }}
        </div>
      </div>
    </div>
    <div class="info-card productive">
      <div class="icon-wrapper">
        <v-icon> mdi-clock-check-outline </v-icon>
      </div>
      <div>
        <div class="label">Productive</div>
        <div class="value">
          {{
            data && data.production
              ? `${Math.floor(data.production / 60)} hours ${Math.round(
                  data.production % 60
                )} mins`
              : ""
          }}
        </div>
      </div>
    </div>

    <div class="info-card idle">
      <div class="icon-wrapper">
        <v-icon>mdi-ticket-confirmation-outline</v-icon>
      </div>
      <div>
        <div class="label">Meeting Time</div>
        <div class="value">
          {{
            data && data.meeting
              ? `${Math.floor(data.meeting / 60)} hours ${Math.round(
                  data.meeting % 60
                )} mins`
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["date", "data"],
  methods: {
    moment,
    changeDate(direction) {
      let newDate = moment(this.date, "YYYY-MM-DD")
        .add(direction, "days")
        .format("YYYY-MM-DD");
      this.$emit("changeDate", newDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .date-navigation {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 4px;
    background-color: #ebf1f3;
    border: 1px solid #d2d2d2;
    button {
      color: #1258ab;
    }
  }
  .info-card {
    padding: 15px;
    border-radius: 4px;
    display: flex;
    gap: 1rem;
    .label {
      font-size: 0.875rem;
      font-weight: 400;
    }
    .value {
      font-size: 0.725rem;
      font-weight: 700;
    }
  }
  .login {
    background-color: #defff3;
    border: 1px solid #03e697;
    .icon-wrapper {
      background-color: #defff3;
      border: 1px solid #03e697;
      color: #03e697;
      height: fit-content;
      width: fit-content;
      border-radius: 12px;
      padding: 0.3rem;
    }
  }
  .ts-logout {
    background-color: #fdecec;
    border: 1px solid #f71515;
    .icon-wrapper {
      background-color: #fdecec;
      border: 1px solid #f71515;
      color: #f71515;
      height: fit-content;
      width: fit-content;
      border-radius: 12px;
      padding: 0.3rem;
    }
  }
  .break {
    background-color: #ecf9fd;
    border: 1px solid #0fb4e7;
    .icon-wrapper {
      background-color: #e11d85;
      color: #ffffff;
      height: fit-content;
      width: fit-content;
      border-radius: 4px;
      padding: 0.3rem;
    }
  }
  .idle {
    background-color: #ecf9fd;
    border: 1px solid #0fb4e7;
    .icon-wrapper {
      background-color: #defff3;
      border: 1px solid #03e697;
      color: #03e697;
      height: fit-content;
      width: fit-content;
      border-radius: 12px;
      padding: 0.3rem;
    }
  }
  .productive {
    background-color: #ecf9fd;
    border: 1px solid #0fb4e7;
    .icon-wrapper {
      background-color: #defff3;
      border: 1px solid #03e697;
      color: #03e697;
      height: fit-content;
      width: fit-content;
      border-radius: 12px;
      padding: 0.3rem;
    }
  }
}
</style>
