<template>
    <div class="external-parties-container">
      <div class="external-card">
        <div class="title">External Parties</div>
        <div v-if="partiesData.length">
          <div class="party-item" v-for="(party, index) in partiesData" :key="index">
            <div class="party-header">
              <v-icon class="icon">mdi-domain</v-icon>
              <span class="party-name">{{ party.name }}</span>
            </div>
            <div class="details-grid">
              <div class="detail-item">
                <v-icon class="icon">mdi-email</v-icon>
                <span class="label">Email:</span>
                <span class="value">{{ party.email }}</span>
              </div>
              <div class="detail-item">
                <v-icon class="icon">mdi-phone</v-icon>
                <span class="label">Phone:</span>
                <span class="value">{{ party.phone }}</span>
              </div>
              <div class="detail-item">
                <v-icon class="icon">mdi-map-marker</v-icon>
                <span class="label">Address:</span>
                <span class="value">{{ party.address }}</span>
              </div>
              <div class="detail-item">
                <v-icon class="icon">mdi-earth</v-icon>
                <span class="label">Country:</span>
                <span class="value">{{ party.country }}</span>
              </div>
              <div class="detail-item">
                <v-icon class="icon">mdi-briefcase</v-icon>
                <span class="label">Type:</span>
                <span class="value">{{ party.type }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">No external parties available.</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      partiesData: {
        type: Array,
        required: true,
        default: () => [
          {
            name: "ABC Supplies",
            email: "contact@abc.com",
            phone: "+1 123 456 7890",
            address: "123 Business St, New York",
            country: "USA",
            type: "Supplier",
          },
          {
            name: "XYZ Logistics",
            email: "info@xyzlogistics.com",
            phone: "+44 7890 123456",
            address: "456 Warehouse Ave, London",
            country: "UK",
            type: "Logistics",
          },
        ],
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .external-parties-container {
    display: flex;
  }
  .external-card {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .party-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 10px;
    background: #f8f9fa;
  }
  .party-header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .details-grid {
    display: grid;
    gap: 10px;
  }
  .detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .icon {
    font-size: 1.25rem;
    color: #007bff;
  }
  .label {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .value {
    font-size: 0.875rem;
  }
  .no-data {
    text-align: center;
    font-size: 0.875rem;
    color: #777;
  }
  </style>
  