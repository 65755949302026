<template>
    <div class="gallery-container">
      <div class="title">
        <span>Images</span>
        <span>
          <v-icon class="icon" @click="prev" :class="{ disabled: currentPage === 0 }">mdi-chevron-left</v-icon>
          <v-icon class="icon" @click="next" :class="{ disabled: currentPage === totalPages - 1 }">mdi-chevron-right</v-icon>
        </span>
      </div>
  
      <div v-if="hasImages" class="image-container">
        <div v-for="(image, index) in paginatedImages" :key="index" class="image-wrapper">
          <img :src="image" alt="Gallery Image" />
        </div>
      </div>
  
      <div v-else class="no-images">No images available</div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageData: {
        type: Array,
        default: () => [], 
      },
    },
    data() {
      return {
        currentPage: 0,
        perPage: 4,
      };
    },
    computed: {
      hasImages() {
        return Array.isArray(this.imageData) && this.imageData.length > 0;
      },
      totalPages() {
        return this.hasImages ? Math.ceil(this.imageData.length / this.perPage) : 1;
      },
      paginatedImages() {
        if (!this.hasImages) return [];
        const start = this.currentPage * this.perPage;
        return this.imageData.slice(start, start + this.perPage);
      },
    },
    methods: {
      next() {
        if (this.hasImages && this.currentPage < this.totalPages - 1) {
          this.currentPage++;
        }
      },
      prev() {
        if (this.hasImages && this.currentPage > 0) {
          this.currentPage--;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .gallery-container {
    width: 100%;
  }
  
  .title {
    font-size: 0.875rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  
    .icon {
      cursor: pointer;
      margin: 0 5px;
      font-size: 1.5rem;
      color: #333;
      
      &.disabled {
        color: #ccc;
        pointer-events: none;
      }
    }
  }
  
  .image-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
  
  .image-wrapper {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 8px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .no-images {
    text-align: center;
    font-size: 1rem;
    color: #888;
    padding: 20px;
  }
  </style>
  